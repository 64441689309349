/* eslint-disable no-console,no-undef, camelcase */
/* eslint-disable react/no-unknown-property */
import React, { Component, Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isNull from 'lodash/isNull';
import { Layout, ChallengeHeader, SeeAllButton, ChallengeSvgContainer, ChallengeSideLayout, ChallengeDashboardContainer, ChallengeDashboardContainermain, NewConnectDevice, ChallengeHeadermain, NewChallengeHeader, DeviceContent, DeviceWrapper, FitnessGoalWrapper, DivContainer, ChallengeInvitationWrapper, InvitationCardWrapper, InvitationButtonWrapper } from './styles';
import Waiting from '../Waiting';
import {
  getActiveTab, setActiveTab, getBehvaiorChallengeByIdAction, UpdateUserLiveChallengeStatus, featuredChallengesAPI,teamChallengesAPI, getChallengeLibraryBySubcategory, setUserLiveChallengeStatus,
  toggleDummyChallenges, revokeDevice, getListOfConnectedDevices, joinOrLeaveChallenge, getUser, getUserRecommendedChallenges, getActivityChallengeByIdAction, getUserRecommendedWellnessChallenges, getListOfThingsToCompleteAPI, getUserChallengeInvitationAPI, getProfileActivityData
} from '../../redux/actions';
import _, { isUndefined } from 'lodash';
import ChallengeSlider from './ChallengeSlider';
import { ChallengeLength, ChallengeTitle, ImageContainer, SlideShowCard } from "./ChallengeSlider/styles";
import ImageThumbnail from "../ImageThumbnail";
import { ImageUrl, imgPath } from "../../utils/constants";
// import { FeaturedChallenges } from "../index";
const ChallengeInfoPopup = React.lazy(() => import("./ChallengeInfoPopup"));
const InviteAmigoPopUp = React.lazy(() => import("../ChallengeDetailsV2/InviteAmigoPopUp/index"));
import { withTranslation } from 'react-i18next';
import RecommendedChallenge from './ChallengeSlider/RecommendedChallenge';
import { CountDiv, NewTotalPoints } from './ChallengeSlider/fitnessScoreCardStyles';
import { commaSeperator, convertMilesToKm } from '../../utils/methods';
import FeatureCompanyChallenge from './FeatureCompanyChallenge';
const TeamChallengeInvitePopup = React.lazy(() => import('./TeamChallengeInvitePopup'));
import { JoinOrLeaveChallenge, LeftTeamChallenge, TeamChallenges } from '../../redux/constants/apiConstants';
import { toast } from 'react-toastify';
import { http } from '../../redux/http';
import LazyImage from '../common/LazyImage/LazyImage';
import { BuddyTab, BuddyTabContainer } from '../PeopleHomeV2/styles';
import { ChallengeHeading } from '../CreateEvent/style';
import { ToolTip } from '../AdminTeamCreation/styles';
const ConnectDeviceModal = React.lazy(() => import('../ConnectDeviceModal'));
const StartUserChallenge = React.lazy(() => import('../StartUserChallenge'));
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InputGoalsV2 from '../InputGoalsV2';
import DailyFitnessActivityNew from '../ContentV2/DailyDashboardNew/DailyFitnessActivityNew';
import moment from 'moment';
import { getRemoveUserInviteForChallenge } from '../../redux/actions/userProfileActions';
import { LeftArrowCarousel, RightArrowCarousel } from '../../utils/icons';

import {TabContainer, TabView, IconWrapper} from '../ChallengeDetailsV2/styles';
import HoverButton from '../common/HoverButtonV2';
import NewSingleChallenge from './DashboardCards/NewSingleChallenge';
import SkeletonLoder from '../common/skeletonLoder';
const currentMonthName = moment().format('MMMM');
class ChallengesDashboard extends Component {
  constructor() {
    super();
    this.state = {
      modalName: '',
      completedChallengeList: false,
      showChallengeInfo: false,
      selectedChallenge: {},
      onHover: -1,
      showDeviceModal: false,
      completedChallengeButton: true,
      showMore: false,
      featureShowMore: false,
      upcomingShoeMore: false,
      challengeType: 'activity',
      showTeamChallnegeInvite:false,
      teamChallenges:[],
      showTeamChallengeInvite:false,
      activePopupTeamChallenge: {title:'', start_date:'', image:'', id:''},
      currentSlide:1
    };
  }

  componentDidMount() {
    this.getTeamChallenges();
    const {  getActiveTab, featuredChallengesAPI, /*featureChallengesData,*/getUserRecommendedChallenges, getUserRecommendedWellnessChallenges, fetchConnectedDevices,fetchListOfThingsToComplate,getUserChallengeInvitationAPI, getProfileActivityData } = this.props;
    fetchConnectedDevices();
    getActiveTab();
    fetchListOfThingsToComplate()
    // getUser();
    featuredChallengesAPI();
    // teamChallengesAPI();
    getUserChallengeInvitationAPI();
    getUserRecommendedChallenges();
    getUserRecommendedWellnessChallenges();
    getProfileActivityData();
  }

  componentDidUpdate() {
    if (typeof (this.startChallenge) !== 'undefined' && this.startChallenge !== null) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 400);
    }
  }

  getTeamChallenges = async() => {
    const AuthToken = localStorage.getItem('AUTH_TOKEN');
    try {
      const res = await http.get(`${TeamChallenges}`, {headers: {AUTHTOKEN: AuthToken}});
      if (res.message) {
        toast.error(res.message);
      }else{
        this.setState({ teamChallenges: res.challenge_invitation});
        if(res && !isNull(res.challenge_invitation)){
          if(res.challenge_invitation.length > 0){
            for (let i = 0; i < res.challenge_invitation.length; i++) {
              if(res.challenge_invitation[i].is_team_challenge === 1){
                this.setState({ activePopupTeamChallenge: res.challenge_invitation[i] },()=>{
                  this.showTeamChallengeModal();
                });
                break;
              }
            
            }
    
          }
        }
      }
    }catch(error){
      toast.error(error.message);
      // dispatch(networkError(error.message));
    }


  };
  onTeamChallengeModalClose = ()=>{
    this.setState({showTeamChallengeInvite:false})
  }
  showTeamChallengeModal = ()=>{
    this.setState({showTeamChallnegeInvite:true})
  }

  onNoClick = async()=>{
    const AuthToken = localStorage.getItem('AUTH_TOKEN');
    try {
      const res = await http.post(`${LeftTeamChallenge}`, {challenge_id:this.state.activePopupTeamChallenge.id}, {headers: {AUTHTOKEN: AuthToken}});
      if (res.message) {
        toast.error(res.message);
      }else{
        this.setState({showTeamChallnegeInvite:false});
        this.getTeamChallenges();
        this.props.featuredChallengesAPI();
      }
    }catch(error){
      toast.error(error.message);
      // dispatch(networkError(error.message));
    }
    
  }

  onYesClick = async()=>{
    const AuthToken = localStorage.getItem('AUTH_TOKEN');
    
    try {
      const res = await http.post(`${JoinOrLeaveChallenge}`, {challenge_id:this.state.activePopupTeamChallenge.id,is_joined: 1}, {headers: {AUTHTOKEN: AuthToken}});
      if (res.message) {
        toast.error(res.message);
      }else{
        this.setState({showTeamChallnegeInvite:false});
        this.props.history.push(`/challenges/${this.state.activePopupTeamChallenge.id}`)
        // this.getTeamChallenges();
        // this.props.featuredChallengesAPI();
      }
    }catch(error){
      toast.error(error.message);
      // dispatch(networkError(error.message));
    }

  }

  close = () => (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_14978_75731"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={24}
        height={24}
      >
        <rect width={24} height={24} fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask0_14978_75731)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.3605 0.678084C22.6358 -0.0466026 21.4608 -0.0466023 20.7361 0.678084L11.9067 9.50756L3.07795 0.678849C2.4145 0.0154025 1.33884 0.0154032 0.675399 0.678849C0.0119522 1.3423 0.0119521 2.41796 0.675399 3.0814L9.50411 11.9101L0.675642 20.7386C-0.0490441 21.4633 -0.0490441 22.6382 0.675643 23.3629C1.40033 24.0876 2.57528 24.0876 3.29996 23.3629L12.1284 14.5344L20.9577 23.3637C21.6211 24.0271 22.6968 24.0271 23.3602 23.3637C24.0237 22.7002 24.0237 21.6246 23.3602 20.9611L14.531 12.1319L23.3605 3.30241C24.0851 2.57772 24.0851 1.40277 23.3605 0.678084Z"
          fill="#9C9C9C"
        />
      </g>
    </svg>
  );

  redirectToChallenges = () => {
    this.setState({showMore: false})
  };

  showModal = (name) => {
    this.setState({
      modalName: name
    });
  };

  hideModal = () => {
    this.setState({
      modalName: ''
    });
  };

  onEnterMouse = (id) => {
    this.setState({
      onHover: id
    })
  };

  onLeaveMouse = () => {
    this.setState({
      onHover: -1
    })
  };

  hideDeviceModal = () => {
    this.setState({ showDeviceModal: false });
  };

  displayDeviceModal = () => {
    this.props.fetchConnectedDevices();
    this.setState({ showDeviceModal: true })
  };

  showMoreCards = () => {
    this.setState((prev) => ({ showMore: !prev.showMore }))
  };

  manageChallenge = (type) => {
    this.setState({
      challengeType: type
    })
  };


  renderChallenges = (challenges) => {
    const { history, toggleDummyChallenges, t, activeChallengeCompany } = this.props;
    // const { showMore } = this.state;
    challenges = challenges.sort((a,b) => new Date(a.start_date) - new Date(b.start_date));
    const slicedUserData = challenges.slice(0, 3);
    const hasLessThanFourChallenges = slicedUserData?.length < 3;
    // if (showMore == true) {
    //   return (
    //     <div className="first">
    //       <ChallengeHeadermain>
    //         <div>
    //           <ChallengeHeader color={1}>{t("My Active Challenges")}</ChallengeHeader>
    //         </div>
    //         <div>
    //           {activeChallengeCompany && activeChallengeCompany.length > 2 ? <SeeAllButton onClick={() => this.showMoreCards()} color={"color"}>{this.state.showMore ? t('See Less <') : t('See All >')}</SeeAllButton> : ''}
    //         </div>
    //       </ChallengeHeadermain>
    //       <div className="siglneCardmain">
    //         {
    //           challenges.map((challenge, index) =>
    //             <NewSingleChallenge challenge={challenge} history={history} key={index} toggleDummyChallenges={toggleDummyChallenges} margin={index} />
    //           )
    //         }
    //       </div>
    //     </div>
    //   )
    // }

    return (
      <div className="first">
        <ChallengeHeadermain>
          <div>
            <ChallengeHeader style={{color:"#005c87"}}>{t("My Active Challenges")}</ChallengeHeader>
          </div>
          <div>
            {activeChallengeCompany && activeChallengeCompany.length > 3 ? <SeeAllButton onClick={() => this.showMoreCards()} color={"color"}>{this.state.showMore ? t('See Less <') : t('See All >')}</SeeAllButton> : ''}
          </div>
        </ChallengeHeadermain>
        <div className="siglneCardmain"> 
          {
            slicedUserData?.map((challenge, index) =>
              <NewSingleChallenge onEnterMouse={this.onEnterMouse} onLeaveMouse={this.onLeaveMouse} onHover={this.state.onHover} challenge={challenge} history={history} key={index} toggleDummyChallenges={toggleDummyChallenges} margin={index} />
            )
          }
          {hasLessThanFourChallenges && <div style={{width:"399px", height:"334px", border: "3px dashed #005C874D",borderRadius:"6px", display:"flex", alignItems:'center', justifyContent:"center"}}>
            <p style={{width:"266px", color:"#6399af", textAlign:'center', fontSize: '16px', lineHeight:'20px',fontWeight: 400}}>{this.props.t("Launch a Challenge from below")}
              {this.props.t("recommended activity and Wellness")}
              {this.props.t("Challenges and earn the Rewards points.")}</p>  
          </div>}
        </div>
      </div>
    )
  };

  renderRecommendedChallenges = (userRecommendedChallengeData) => (
    (Array.isArray(userRecommendedChallengeData) && userRecommendedChallengeData.length > 0 && userRecommendedChallengeData[0].length > 0) && 
    userRecommendedChallengeData.map((data, index) => (
      (this.state.completedChallengeList || this.props.liveChallengeStatus || this.props.runningStatus) ? null :
        <div className={data.length > 0 && "recommended_class"} key={index} style={{
          marginTop:
            (this.props.activeChallengeCompany &&
              this.props.activeChallengeCompany.length > 0)
              ? '60px'
              : '0px',
        }}>
          {data.length > 0 ? <NewChallengeHeader color={1}>{this.props.t("Recommended Activity Challenges")}</NewChallengeHeader> : null}
          <RecommendedChallenge challengesType={"activity"} onEnterMouse={this.onEnterMouse} onLeaveMouse={this.onLeaveMouse} onHover={this.state.onHover} data={data} history={this.props.history} key={index} challengeNavigation={this.challengeNavigation} runningStatus={this.props.runningStatus} getBehaviorChallengeByIdAction={this.props.getBehvaiorChallengeByIdAction} activity={false} setUserLiveChallengeStatus={setUserLiveChallengeStatus} toggleDummyChallenges={this.props.toggleDummyChallenges} getActivityChallengeByIdAction={this.props.getActivityChallengeByIdAction} manageChallenge={this.manageChallenge} />
        </div>
    ))
  );
  
  renderRecommendedWellnessChallenges = (userRecommendedWellnessChallengeData) => (
    userRecommendedWellnessChallengeData.length > 0 && userRecommendedWellnessChallengeData.map((data, index) => (
      (this.state.completedChallengeList || this.props.liveChallengeStatus || this.props.runningStatus) ? null :
        <div className={data.length > 0 && "recommended_wellness_class"} key={'index'} style={{
          marginTop:
            (
              (this.props.activeChallengeCompany && this.props.activeChallengeCompany.length > 0) ||
              (this.props.userRecommendedChallengeData && this.props.userRecommendedChallengeData.length > 0)
            ) &&
            data.length > 0 
              ? '60px'
              : '0px',
        }}>
          {data.length > 0 ? <NewChallengeHeader color={1}>{this.props.t("Recommended Wellness Challenges")}</NewChallengeHeader> : null}
          <RecommendedChallenge challengesType={"wellness"} onEnterMouse={this.onEnterMouse} onLeaveMouse={this.onLeaveMouse} onHover={this.state.onHover} data={data} history={this.props.history} key={index} challengeNavigation={this.challengeNavigation} runningStatus={this.props.runningStatus} getBehaviorChallengeByIdAction={this.props.getBehvaiorChallengeByIdAction} activity={false} setUserLiveChallengeStatus={setUserLiveChallengeStatus} toggleDummyChallenges={this.props.toggleDummyChallenges} getActivityChallengeByIdAction={this.props.getActivityChallengeByIdAction} manageChallenge={this.manageChallenge} />
        </div>
    ))
  );

  FitnessScoreCardRender = () =>
    (
      <div>
        <ChallengeHeader style={{color:"#005c87", marginBottom:'30px', fontSize: localStorage.getItem("lang") == "fr" && "16px" }}>
          {
            (localStorage.getItem("lang") != "fr") ? 
              `${this.props.t("My")} ${new Date().getFullYear()} ${this.props.t("Fitness Score")}`
              :`${this.props.t("My")} ${this.props.t("Fitness Score")} ${new Date().getFullYear()}`
          }
        </ChallengeHeader>
      </div>
    );

  upComingCardMore = () => {
    this.setState((prev) => ({ upcomingShoeMore: !prev.upcomingShoeMore }))
  };

  featuredCards = () => {
    this.setState((prev) => ({ featureShowMore: !prev.featureShowMore }))
  };

  handleBeforeChange = (oldIndex, newIndex) => {
    // Update the current slide index when the slider changes
    this.setState({ currentSlide: newIndex + 1 });
  };

  FeaturedCompanyRender = (featureChallengesData) => {
    const { history, toggleDummyChallenges, isSocialFeedsPage, joinOrLeaveChallenge, featuredWith, isDummyChallenge, t } = this.props;
    // const { featureShowMore } = this.state;
    // const slicedUserData = featureChallengesData.slice(0, 2)
    // if (featureShowMore == true) {
    
    featureChallengesData.sort((a, b) => new Date(a.start_date) - new Date(b.start_date))
      
    const settings = {
      dots: false, 
      infinite: true, 
      speed: 500, 
      slidesToShow: 1, 
      slidesToScroll: 1,
      arrows: false,
      beforeChange: this.handleBeforeChange
    }

    return (
      <div className='first' style={{width:""}}>
        <div style={{display:'flex', justifyContent:'space-between', alignItems:'baseline',height:'24px', marginBottom:'25px'}}>
          <div>
            <ChallengeHeader style={{color:"#005c87"}}> {featureChallengesData && featureChallengesData.length >= 1 ? t("Featured Challenge") : ''}</ChallengeHeader>
          </div>
          {featureChallengesData.length > 1 ? (<div style={{display:'flex', alignItems:'center', gap:'15px'}}>
            <button className="slider-arrow-prev" style={{border:"none", background:"#f7f5eb"}} onClick={() => this.slider.slickPrev()}>
              <LeftArrowCarousel/>
            </button>
            <span style={{color:'#005c87', fontSize:'18px', fontWeight:400}}>{this.state.currentSlide} / {featureChallengesData.length}</span>
            <button className="slider-arrow-next" style={{border:"none",background:"#f7f5eb"}} onClick={() => this.slider.slickNext()}>
              <RightArrowCarousel/>
            </button>
          </div>):null}
        </div>
        <div className='singleCardmainFeature' style={{boxShadow: '0px 8px 25px 0px #0D427040', borderRadius:'6px'}}>
          <Slider className="slick-height" {...settings} ref={(slider) => (this.slider = slider)}>
            {featureChallengesData.map((challenge, index) =>
              <FeatureCompanyChallenge
                challenge={challenge}
                key={index}
                isSocialFeedsPage={isSocialFeedsPage}
                joinOrLeaveChallenge={joinOrLeaveChallenge}
                history={history}
                featuredWith={featuredWith}
                toggleDummyChallenges={toggleDummyChallenges}
                isDummyChallenge={isDummyChallenge} />
            )
            }
          </Slider>   
        </div>
      </div>
    )
  };

  completedChallenges = (challenges) => {
    const { history, t } = this.props;
    return (
      <div>
        <ChallengeHeader>{t('Completed Challenges')}</ChallengeHeader>
        {
          <ChallengeSlider challenges={challenges} history={history} categories={1} />
        }
      </div>
    )
  };

  showCompletedChallenges = () => {
    this.setState((prevState) => ({
      completedChallengeList: !prevState.completedChallengeList,
      completedChallengeButton: !this.state.completedChallengeButton,
    }))
  };

  completedChallengeRedirect = () => {
    this.props.history.push(`/challenges/completed`)
  };

  totalSteps = (dailyActivityData) => (
    <NewTotalPoints>
      <div>
        <LazyImage src={ImageUrl + '/ChallengeDetailsScreen/new_steps.svg'}/>
      </div>
      <div>
        <div>{this.props.t("Total Steps Walked Around World")}</div>
        <CountDiv style={{ color: '#76ab78' }}>
          {dailyActivityData && _.isUndefined(dailyActivityData.steps) ? 0 : commaSeperator(parseInt(dailyActivityData?.steps,10))}
          <span>&nbsp;&nbsp;{this.props.t("Steps")}</span>
        </CountDiv>
      </div>
    </NewTotalPoints>
  );

  totalCalories = (dailyActivityData) => (
    <NewTotalPoints>
      <div>
        <LazyImage src={ImageUrl + '/ChallengeDetailsScreen/new_calorie.svg'}/>
      </div>
      <div>
        <div>{this.props.t("Total Calories Burned")} </div>
        <CountDiv style={{ color: '#F6B479' }}>
          {dailyActivityData && _.isUndefined(dailyActivityData.calories) ? 0 : commaSeperator(parseInt(dailyActivityData?.calories,10))}
          <span>&nbsp;&nbsp;{this.props.t("Calories")}</span>
        </CountDiv>
      </div>
    </NewTotalPoints>
  );

  totalDistance = (dailyActivityData) => {
    const DistanceUnit = localStorage.getItem('DISTANCE');
    return (
      <NewTotalPoints>
        <div>
          <LazyImage src={ImageUrl + '/ChallengeDetailsScreen/new_location.svg'}/>
        </div>
        <div>
          <div>{this.props.t("Total Distance Marathons")}</div>
          <CountDiv style={{ color: '#69C2FF' }}>
            {dailyActivityData && _.isUndefined(dailyActivityData.distance) ? 0 : DistanceUnit === "KILOMETER" ? commaSeperator(convertMilesToKm(dailyActivityData && dailyActivityData.distance)) : commaSeperator(parseInt(dailyActivityData?.distance,10))}
            <span>&nbsp;&nbsp;{DistanceUnit === "KILOMETER" ? "Km" : this.props.t("Miles")}</span>
          </CountDiv>
        </div>
      </NewTotalPoints>)
  };

  challengeCompletedCards = (challenges, categories) => {
    const { history, runningStatus } = this.props;
    return (
      <div ref={(startChallenge) => this.startChallenge = startChallenge}>
        {
          challenges.map((data, index) => (
            <SlideShowCard width={1} opacity={'0.3'} key={index} bgColor={"#000000"}>
              <ImageContainer height={'200px'} filter={data['challenge_status'] === 'over' ? 1 : 0}>
                <ImageThumbnail src={`${ImageUrl}/${data['image']}`} alt={"slide"} />
              </ImageContainer>
              <div className="bg-opacity" onClick={() => runningStatus ? this.challengeNavigation(data.id) : history.push(`/challenges/${data.id}`)} />
              {runningStatus ?
                <div className="information-icon" onClick={() => this.challengeInfoPopup(data)}>
                  <img src={`${imgPath}/information_Icon.svg`} />
                </div> : null
              }
              <ChallengeTitle onClick={() => runningStatus ? this.challengeNavigation(data.id) : history.push(`/challenges/${data.id}`)}>
                <ChallengeSvgContainer width={runningStatus ? '25px' : '15px'}>
                  {
                    runningStatus ? <img src={`${ImageUrl}/${data['event_icon']}`} alt={data['event_type']} /> : <img src={`${imgPath}/information_icon.png`} />
                  }
                </ChallengeSvgContainer>
                <div className="title">
                  {runningStatus ? data.name : data.title}
                </div>
              </ChallengeTitle>
              <ChallengeLength bottom={categories}>
                <div className="challenge-remaining">
                  <div className="challenge-text">
                    {
                      (data['challenge_status'] === 'over') ? <div><span style={{ fontSize: '12px' }}>Finished</span></div> : <div><span style={{ fontSize: '12px' }}>{data.duration || ''}</span></div>
                    }
                  </div>
                </div>
                <div className={"points"}>
                  {data['challenge_point'] || 0} pts
                </div>
              </ChallengeLength>
            </SlideShowCard>
          ))
        }
      </div>
    );
  };
  updateLiveStatus = () => {
    const { UpdateUserLiveChallengeStatus } = this.props;
    UpdateUserLiveChallengeStatus();
  };
  challengeInfoPopup = (data) => {
    this.setState((prevState) => ({
      showChallengeInfo: !prevState.showChallengeInfo,
      selectedChallenge: data
    }))
  };
  teamChallnegeInvitePopup = (data) => {
    this.setState({showTeamChallnegeInvite:!this.state.showTeamChallnegeInvite, selectedChallenge:data})
  };
  challengeNavigation = (id) => {
    const { getBehvaiorChallengeByIdAction } = this.props;
    getBehvaiorChallengeByIdAction(id, true);
  };
  challengeNavigations = (id) => {
    const { getBehvaiorChallengeByIdAction } = this.props;
    getBehvaiorChallengeByIdAction(id, true);
  };

  showMonthlyGoals = () => (
    // const { monthly_goals } = this.props.listOfThingToComplate;
    <FitnessGoalWrapper>
      {/* <p style={{color:"#005c87"}}>Fitness Goal</p> */}
      <div style={{position:'relative'}}>
        <LazyImage src={ImageUrl + '/ChallengeDetailsScreen/Fitness_goal.svg'} alt="icon2" style={{width: '100%', height:"100%", borderRadius:'6px'}}/>
        <div style={{position:'absolute',top:20, right:20, display: "flex", flexDirection: "column", alignItems: "center"}}>
          <p style={{width:'152px', fontFamily:'Rubik-medium', fontSize:'16px',textAlign:'center', margin: typeof InstallTrigger !== 'undefined' ? '0px 40px 0px 20px' :'0px 40px 10px 30px', color:'#005C87', fontWeight:500}} title={this.props.t("Set Goals and Motivate Yourself")}>{this.props.t("Set Goals and Motivate Yourself")}</p>
          <button style={{width: '195px',/*  margin: '15px 20px 0px 50px', */height: '42px',border:'none', borderRadius:'6px',fontFamily:'Rubik-medium',fontSize: '16px', color:'#005C87', background:"white", fontWeight:500 ,boxShadow: '0px 8px 24px 0px #005C8780'}} onClick={() => this.showModal("monthly_goals")}>{this.props.t("Create Goal")}</button>
        </div>
      </div>
    </FitnessGoalWrapper>
  )

  joinChallenge = (challengeId, isJoined) => {
    const { joinOrLeaveChallenge, history } = this.props;
    const data = {
      challenge_id: challengeId,
      is_joined: isJoined
    };
    joinOrLeaveChallenge(data);
    history.push(`/challenges/${challengeId}`);
  };

  removeChallenge = (challengeId) => {
    const { getRemoveUserInviteForChallenge } = this.props;
    const data = {
      challenge_id: challengeId,
    };
    getRemoveUserInviteForChallenge(data);
  };

  calculateTimeSinceLastSync = (lastSync) => {
    const lastSyncTimestamp = new Date(lastSync).getTime();
    const currentTimestamp = new Date().getTime();
    const timeDifferenceMillis = currentTimestamp - lastSyncTimestamp;
    let lang = localStorage.getItem("lang");
  
    const days = Math.floor(timeDifferenceMillis / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifferenceMillis % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  
    return lang != "fr" ? `${days} ${this.props.t("days")} ${hours} ${this.props.t("hours ago")}` : `${days} ${this.props.t("days")} ${this.props.t("ago")} ${hours} ${this.props.t("hours")}`  ;
  }

  headerView = () =>{ 
    const { t } = this.props;
    return (
      <BuddyTabContainer marginBottom="25px" style={{marginTop:"25px"}}>
        <BuddyTab padding active={true} margin={"10px 0px 10px 15px"} 
          // style={{color:'#005c87'}}
        >
          {t("Dashboard")}
        </BuddyTab>
        <BuddyTab
          width
          padding
          margin={"10px 0px 10px 0px"}
          // style={{color:'#005c87'}}
          active={false}
          onClick={() => this.completedChallengeRedirect()}
        >
          {" "}
          {t("Completed")}
        </BuddyTab>
        {this.props.isAdmin && <BuddyTab
          width
          padding
          active={false}
          margin={"10px 0px 10px 0px"}
          onClick={() => this.props.history.push('/challenges/categories')}
          // style={{color:'#005c87'}}
        >
          {" "}
          {t("Library")}
        </BuddyTab>}
      </BuddyTabContainer>
    )
  };
  schedule = () =>(
    <svg
      width="20px"
      height="auto"
      viewBox="-5 0 25 25"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="icons"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="ui-gambling-website-lined-icnos-casinoshunter"
          transform="translate(-1913.000000, -158.000000)"
          fill="#0D4270"
          fillRule="nonzero"
        >
          <g id="1" transform="translate(1350.000000, 120.000000)">
            <path
              d="M566.453517,38.569249 L577.302459,48.9938158 L577.39261,49.0748802 C577.75534,49.423454 577.968159,49.8870461 578,50.4382227 L577.998135,50.6228229 C577.968159,51.1129539 577.75534,51.576546 577.333675,51.9774469 L577.339095,51.9689832 L566.453517,62.430751 C565.663694,63.1897497 564.399001,63.1897497 563.609178,62.430751 C562.796941,61.650213 562.796941,60.3675924 563.609432,59.5868106 L573.012324,50.5572471 L563.609178,41.4129456 C562.796941,40.6324076 562.796941,39.349787 563.609178,38.569249 C564.399001,37.8102503 565.663694,37.8102503 566.453517,38.569249 Z"
              id="left"
              transform="translate(570.500000, 50.500000) scale(-1, 1) translate(-570.500000, -50.500000) "
            ></path>
          </g>
        </g>
      </g>
    </svg>
  ) 

  headerViewV2 = () =>(
    <TabContainer marginBottom="0px" marginTop="25px" style={{width:"1246px", margin:'auto', marginTop:"25px", marginBottom:'25px'}}>
      <IconWrapper margin="auto 0px auto 0">
        <HoverButton
          title={this.props.t("Back")}
          width="20px"
          height="24px"
          svgPath={this.schedule()} 
          onClick={() => this.updateLiveStatus()}
          backgroundColor={"#005C870D"}
          backgroundColorActive={"#005C87"}
        />
      </IconWrapper>
      <TabView 
        width
        padding
        active={1}
      >
        {this.props.t("Overview")}
      </TabView>
    </TabContainer>
  )

  render() {
    const { history,fname, isLoading, activeChallengeCompany,
      liveChallengeStatus, runningStatus,challengeInvitation,
      challengelibrarySubCategories, activityStatus, activityChallenge, t, devices, revokeDevice, devicesDisconnected,/*getBehvaiorChallengeByIdAction,getChallengeLibraryBySubcategory,setUserLiveChallengeStatus*/
      dailyActivityData, featureChallengesData, userRecommendedChallengeData, userRecommendedWellnessChallengeData,listOfThingToComplate } = this.props;
    const { completedChallengeList, showChallengeInfo, selectedChallenge, showDeviceModal, /*completedChallengeButton,*/ challengeType } = this.state;
    if (isLoading  || _.isNull(featureChallengesData) || _.isNull(userRecommendedChallengeData) || _.isNull(listOfThingToComplate) || _.isNull(userRecommendedWellnessChallengeData) || !activeChallengeCompany || _.isNull(userRecommendedWellnessChallengeData) || isUndefined(challengeInvitation)) {
      return <div style={{width:'1246px', margin:"auto"}}>
        <SkeletonLoder width={"100%"} height={"60px"} style={{margin:"25px 0px 25px 0px"}}/>
        <SkeletonLoder width={"100%"} height={"364px"} style={{marginBottom:"60px"}}/>
        <div width={"1246px"} height={"354px"} style={{margin:'0px 0px 60px 0px'}}> 
          <SkeletonLoder style={{width:"310px", height:"24px", marginBottom:"25px"}}/>
          <div style={{display:'flex', gap:"25px"}}>
            <SkeletonLoder width={"399px"} height={"334px"}/>
            <SkeletonLoder width={"399px"} height={"334px"}/>
            <SkeletonLoder width={"399px"} height={"334px"}/>
          </div>
        </div>
        <div width={"1246px"} height={"354px"} style={{margin:'0px 0px 60px 0px'}}> 
          <SkeletonLoder style={{width:"310px", height:"24px", marginBottom:"25px"}}/>
          <div style={{display:'flex', gap:"25px"}}>
            <SkeletonLoder width={"399px"} height={"334px"}/>
            <SkeletonLoder width={"399px"} height={"334px"}/>
            <SkeletonLoder width={"399px"} height={"334px"}/>
          </div>
        </div>
        <div width={"1246px"} height={"354px"} style={{margin:'0px 0px 60px 0px'}}> 
          <SkeletonLoder style={{width:"310px", height:"24px", marginBottom:"25px"}}/>
          <div style={{display:'flex', gap:"25px"}}>
            <SkeletonLoder width={"399px"} height={"334px"}/>
            <SkeletonLoder width={"399px"} height={"334px"}/>
            <SkeletonLoder width={"399px"} height={"334px"}/>
          </div>
        </div>
      </div>
    }
    const { monthly_goals } = listOfThingToComplate;
    let completedChallengesArray = [];
    // challengesData.map((data) => {
    //   data['challenge_status'] === 'over' && completedChallengesArray.push(data);
    // });
    const connectedDevices = devices.filter((devices) => devices.is_connected);
  
    let ChallengeInvitationData = [];
    if (challengeInvitation && !isUndefined(challengeInvitation) && challengeInvitation.challenge_invitation && !isUndefined(challengeInvitation.challenge_invitation[0])) {
      ChallengeInvitationData = challengeInvitation && !isUndefined(challengeInvitation) && challengeInvitation.challenge_invitation;
    }
    return (
      <ChallengeDashboardContainermain>
        {
          this.state.showMore === false ? 
            <div>
              {!liveChallengeStatus && !runningStatus && this.headerView()}
              {(liveChallengeStatus || runningStatus) && this.headerViewV2()}
              {!liveChallengeStatus && !runningStatus && <div style={{width:'1246px', margin:'auto', marginBottom:featureChallengesData.length >= 1 ? '60px' : '0'}}>
                {featureChallengesData.length >= 1 ? this.FeaturedCompanyRender(featureChallengesData) : null}
              </div>}
              <ChallengeDashboardContainer>
                <Layout>
                  {
                    !completedChallengeList && !liveChallengeStatus && !runningStatus ? activeChallengeCompany.length && !isNull(activeChallengeCompany[0]) ?
                      this.renderChallenges(activeChallengeCompany) : completedChallengesArray.length  ? null : null : null
                  }
                  { this.renderRecommendedChallenges(userRecommendedChallengeData)}
                  {this.renderRecommendedWellnessChallenges(userRecommendedWellnessChallengeData)}
                  {!runningStatus && !liveChallengeStatus && completedChallengesArray.length > 3 && <SeeAllButton width={completedChallengeList ? 1 : 0} onClick={() => this.showCompletedChallenges()}>{completedChallengeList && <img src={'/public/images/educationAdventureIcons/back-arrow.png'} />} {completedChallengeList ? t('Back') : ''}</SeeAllButton>}
                  {/* {(liveChallengeStatus || runningStatus) && <SeeAllButton width={1} ref={(startChallenge) => this.startChallenge = startChallenge} justify={1} onClick={() => this.updateLiveStatus()}>{<img src={'/public/images/educationAdventureIcons/back-arrow.png'} />} {t('Backk')}</SeeAllButton>} */}
                  {!runningStatus && !liveChallengeStatus && completedChallengeList ? this.challengeCompletedCards(completedChallengesArray, 1) : null}
                  {runningStatus ? challengelibrarySubCategories.length ? this.challengeCompletedCards(challengelibrarySubCategories, 1) : null : null}
                </Layout>
                {!liveChallengeStatus &&
            <ChallengeSideLayout>
              <div>
                {
                  ChallengeInvitationData.length > 0 && <ChallengeInvitationWrapper>
                    <p>Challenge Invitation</p>
                    <InvitationCardWrapper>
                      {ChallengeInvitationData.map((invitation, index) => <div key={index}>
                        <div style={{display:"flex", gap:'10px'}}>
                          <div style={{width:"150px", height:"92px", borderRadius:"6px"}}>
                            <LazyImage src={ImageUrl +"/"+ invitation.image} style={{width:"150px", height:"92px", borderRadius:"6px"}}/>
                          </div>
                          <span style={{color:"#005c87", fontSize:'16px', lineHeight:'20px', width:'208px', fontFamily:'rubik'}}>
                            <span style={{fontFamily:'rubik-medium'}}>{`${invitation.invited_by}${" "}`}</span>
                            invited You to join the
                            <span style={{fontFamily:'rubik-medium'}}>{`${" "}${invitation.title} Challenge starts on ${moment(invitation.start_date).format('MMM DD, YYYY')}`}</span>
                          </span>
                        </div>
                        <InvitationButtonWrapper marginBottom={ChallengeInvitationData && ChallengeInvitationData.length > 1}>
                          <button onClick={() => this.joinChallenge(invitation.id, 1)}>Accept</button>
                          <button onClick={() => this.removeChallenge(invitation.id)}>Reject</button>
                        </InvitationButtonWrapper>
                        {index < ChallengeInvitationData.length - 1 && <div style={{ border: '1px solid #b3cfdb', margin:'25px 0px' }}></div>}
                      </div>)
                      } </InvitationCardWrapper></ChallengeInvitationWrapper>
                }
                { connectedDevices.length === 0 && <NewConnectDevice>
                  <LazyImage src={ImageUrl+"/ChallengeDetailsScreen/new-fitness-device.svg"} alt="icon2" style={{width: '100%',height: '110px', borderRadius:'6px 6px 0px 0px'}}/>
                  <button onClick={this.displayDeviceModal} >
                    <div>{t("Connect Your Device")}</div>
                  </button>
                </NewConnectDevice> }
                { connectedDevices.length > 0 && <NewConnectDevice>
                  {connectedDevices.length > 0 ? (
                    connectedDevices.map((device, index) => (
                      <DeviceWrapper key={index}>
                        <div style={{ display: 'flex', alignItems: 'center', margin: '0px 5px' }}>
                          <LazyImage src={ImageUrl + '/' + device.device_log} alt="icon2" style={{ width: '60px', height: '60px', borderRadius: '6px' }} />
                        </div>
                        <DeviceContent>
                          <p>{device.display_name}</p>
                          <p>{this.props.t("Last Sync")} {this.calculateTimeSinceLastSync(device.last_sync)}</p>
                        </DeviceContent>
                      </DeviceWrapper>
                    ))
                  ) : <LazyImage src={ImageUrl+"/ChallengeDetailsScreen/new-fitness-device.svg"} alt="icon2" style={{width: '100%',height: '110px', borderRadius:'6px 6px 0px 0px'}}/>
                  }
                  <button onClick={this.displayDeviceModal} >
                    <div>{ connectedDevices.length > 0 ? t("Connect More Devices") : t("Connect Fitness Device")}</div>
                  </button>
                </NewConnectDevice>}
              </div>
              <div style={{display:'flex',flexDirection:"column"}}>
                <DivContainer padding={this.props.isAdmin ? '0px' : '0px'}><DailyFitnessActivityNew challenge={1} /></DivContainer>
                {monthly_goals === 0 && this.showMonthlyGoals()}
              </div>
              <div>
                {this.FitnessScoreCardRender()}
                {this.totalSteps(dailyActivityData)}
                {this.totalCalories(dailyActivityData)}
                {this.totalDistance(dailyActivityData)}
              </div>
              <Suspense fallback={<Waiting/>}>
                <ConnectDeviceModal
                  showModal={showDeviceModal}
                  closeModal={this.hideDeviceModal}
                  devices={devices}
                  revokeDevice={revokeDevice}
                  devicesDisconnectedList={devicesDisconnected}
                />
              </Suspense>
            </ChallengeSideLayout>
                }
                {liveChallengeStatus && <Suspense fallback={<Waiting/>}><StartUserChallenge activityStatus={activityStatus} activityChallenge={activityChallenge} history={history} challengeType={challengeType} /></Suspense>}
                <Suspense fallback={<Waiting/>}><ChallengeInfoPopup showModal={showChallengeInfo} onClose={this.challengeInfoPopup} challenge={selectedChallenge} /></Suspense>
                {this.state.showTeamChallnegeInvite && (<Suspense fallback={<Waiting/>}><TeamChallengeInvitePopup showModal={this.state.showTeamChallnegeInvite} onClose={this.teamChallnegeInvitePopup} challenge={this.state.activePopupTeamChallenge} fname={this.props.fname} onNoClick={this.onNoClick} onYesClick={this.onYesClick}/></Suspense>)}
                <Suspense fallback={<Waiting/>}><InviteAmigoPopUp showModal={false} /></Suspense>
                <InputGoalsV2
                  showModal={this.state.modalName === 'monthly_goals'}
                  onHide={this.hideModal}
                  // SetDailyGoal={SetDailyGoal}
                  // inputGoals={inputGoals}
                  userName={fname}
                  month={currentMonthName}
                />
              </ChallengeDashboardContainer>
            </div>
            :
            <ChallengeDashboardContainer style={{marginBottom:'100px'}}>
              <ChallengeHeading style={{marginTop:"25px"}}>
                <ToolTip
                  margin={"auto 15px auto 0"}
                  activeValue={"transparent"}
                  height="40px"
                  width="55px"
                >
                  <HoverButton
                    title="Close"
                    width="24px"
                    height="24px"
                    svgPath={this.close()}
                    fillOpacity={1} 
                    disabled={false} 
                    backgroundColor={"#e6eff3"} 
                    backgroundColorActive={"#005c87"} 
                    activeColor={"#619ab5"} 
                    inActiveColor={"white"}
                    onClick={this.redirectToChallenges}
                  />
                </ToolTip>
                Active Challenges
              </ChallengeHeading>
              <div className="first">
                <ChallengeHeadermain>
                  <div>
                    {activeChallengeCompany && activeChallengeCompany.length > 4 ? <SeeAllButton onClick={() => this.showMoreCards()} color={"color"}></SeeAllButton> : ''}
                  </div>
                </ChallengeHeadermain>
                <div style={{display:'flex', flexWrap:'wrap', width:'100%', gap:'24px'}}>
                  {
                    activeChallengeCompany.map((challenge, index) =>
                      <NewSingleChallenge onEnterMouse={this.onEnterMouse} onLeaveMouse={this.onLeaveMouse} onHover={this.state.onHover} challenge={challenge} history={history} key={index} toggleDummyChallenges={toggleDummyChallenges} showAll={true}/>
                    )
                  }
                </div>
              </div>
            </ChallengeDashboardContainer>
        }
      </ChallengeDashboardContainermain>
    )
  }
}

ChallengesDashboard.propTypes = {
  history: PropTypes.object.isRequired,
  challengesData: PropTypes.array,
  isAdmin: PropTypes.bool,
  activeTab: PropTypes.string.isRequired,
  getActiveTab: PropTypes.func.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  activeChallengeCompany: PropTypes.array,
  upcomingChallenge: PropTypes.array,
  getActiveChallenges: PropTypes.func,
  getBehvaiorChallengeByIdAction: PropTypes.func,
  liveChallengeStatus: PropTypes.bool,
  UpdateUserLiveChallengeStatus: PropTypes.func,
  featuredChallengesAPI: PropTypes.func,
  teamChallengesAPI:PropTypes.func,
  getChallengeLibraryBySubcategory: PropTypes.func,
  challengelibrarySubCategories: PropTypes.array,
  runningStatus: PropTypes.bool,
  runningSubCategory: PropTypes.string,
  setUserLiveChallengeStatus: PropTypes.func,
  activityStatus: PropTypes.bool,
  activityChallenge: PropTypes.object,
  toggleDummyChallenges: PropTypes.func,
  t: PropTypes.func,
  fetchConnectedDevices: PropTypes.func.isRequired,
  devices: PropTypes.array,
  revokeDevice: PropTypes.func.isRequired,
  devicesDisconnected: PropTypes.array,
  dailyActivityData: PropTypes.object,
  getUser: PropTypes.func.isRequired,
  featureChallengesData: PropTypes.array,
  isSocialFeedsPage: PropTypes.bool,
  fetchFeaturedChallenge: PropTypes.func,
  joinOrLeaveChallenge: PropTypes.func.isRequired,
  featuredWith: PropTypes.number,
  isDummyChallenge: PropTypes.bool,
  getUserRecommendedChallenges: PropTypes.func.isRequired,
  getUserRecommendedWellnessChallenges:PropTypes.func.isRequired,
  userRecommendedChallengeData: PropTypes.array,
  userRecommendedWellnessChallengeData: PropTypes.array,
  getActivityChallengeByIdAction: PropTypes.func,
  activityChallengeById: PropTypes.object,
  fname: PropTypes.string,
  userCompany: PropTypes.object,
  teamChallenges:PropTypes.array,
  listOfThingToComplate: PropTypes.object,
  monthlyGoal: PropTypes.object,
  fetchListOfThingsToComplate: PropTypes.func,
  getUserChallengeInvitationAPI: PropTypes.func,
  challengeInvitation: PropTypes.object,
  getRemoveUserInviteForChallenge: PropTypes.func,
  getProfileActivityData: PropTypes.func
};

const mapStateToProps = (state) => ({
  activeTab: state.challenges.activeTab,
  isLoading: state.challenges.isLoading,
  activeChallengeCompany: state.challenges.activeChallengeCompany,
  upcomingChallenge: state.challenges.upcomingChallenge,
  liveChallengeStatus: state.challenges.liveChallengeStatus,
  challengelibrarySubCategories: state.challenges.challengelibrarySubCategories,
  runningStatus: state.challenges.runningStatus,
  runningSubCategory: state.challenges.runningSubCategory,
  activityStatus: state.challenges.activityStatus,
  activityChallenge: state.challenges.activityChallenge,
  onEnterMouse: PropTypes.func.isRequired,
  onLeaveMouse: PropTypes.func.isRequired,
  devices: state.wellnessDashboard.devices,
  devicesDisconnected: state.profileData.devicesDisconnected,
  joinOrLeaveChallenge: PropTypes.func.isRequired,
  dailyActivityData: state.profileData.dailyActivityData,
  featureChallengesData: state.challenges.featureChallenges,
  userRecommendedChallengeData: state.challenges.userRecommendedChallengeData,
  userRecommendedWellnessChallengeData:state.challenges.userRecommendedWellnessChallengeData,
  fname: state.profileData.firstName,
  userCompany: state.profileData.userCompany,
  listOfThingToComplate: state.profileData.listOfThingToComplate,
  monthlyGoal: state.profileData.monthlyGoal,
  challengeInvitation: state.profileData.challengeInvitation
});

const mapDispatchToProps = (dispatch) => ({
  getActiveTab: () => dispatch(getActiveTab()),
  setActiveTab: (tabName) => dispatch(setActiveTab(tabName)),
  getBehvaiorChallengeByIdAction: (id, liveChallenge) => dispatch(getBehvaiorChallengeByIdAction(id, liveChallenge)),
  UpdateUserLiveChallengeStatus: () => dispatch(UpdateUserLiveChallengeStatus()),
  featuredChallengesAPI: () => dispatch(featuredChallengesAPI()),
  teamChallengesAPI: () => dispatch(teamChallengesAPI()),
  getChallengeLibraryBySubcategory: (subcategory, runningStatus) => dispatch(getChallengeLibraryBySubcategory(subcategory, runningStatus)),
  setUserLiveChallengeStatus: (challenge) => dispatch(setUserLiveChallengeStatus(challenge)),
  toggleDummyChallenges: (bool) => dispatch(toggleDummyChallenges(bool)),
  revokeDevice: (name) => dispatch(revokeDevice(name)),
  fetchConnectedDevices: () => dispatch(getListOfConnectedDevices()),
  joinOrLeaveChallenge: (data) => dispatch(joinOrLeaveChallenge(data)),
  getUser: () => dispatch(getUser()),
  getUserRecommendedChallenges: () => dispatch(getUserRecommendedChallenges()),
  getUserRecommendedWellnessChallenges: () => dispatch(getUserRecommendedWellnessChallenges()),
  getActivityChallengeByIdAction: (id, liveChallenge) => dispatch(getActivityChallengeByIdAction(id, liveChallenge)),
  fetchListOfThingsToComplate: () => dispatch(getListOfThingsToCompleteAPI()),
  getUserChallengeInvitationAPI: () => dispatch(getUserChallengeInvitationAPI()),
  getRemoveUserInviteForChallenge: (data) => dispatch(getRemoveUserInviteForChallenge(data)),
  getProfileActivityData: () => dispatch(getProfileActivityData())
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ChallengesDashboard));